import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import PropTypes from 'prop-types';

import { font } from '../consts/style';

const Wrapper = styled.div`
  .listing {
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      gap: 16px;

      a {
        ${font.menuLink}
        color: white;
      }
    }
  }
  margin-bottom: 80px;
`;

export default function LinkListPage({ title, links }) {
  return (
    <Fragment>
      <Wrapper>
        <PageHero title={title} />
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="listing">
          <ul>
            {links.map((link) => (
              <li key={link.slug}>
                <a href={link.slug}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </Wrapper>
    </Fragment>
  );
}

LinkListPage.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
};
