import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LinkListPage from '../components/LinkListPage';

const servicesQuery = graphql`
  query {
    allDatoCmsServicePage {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

export default function ServicesPage() {
  const data = useStaticQuery(servicesQuery);
  const links = data.allDatoCmsServicePage.edges.map((f) => {
    return {
      title: f.node.title,
      slug: '/service/' + f.node.slug,
    };
  });

  return <LinkListPage title="Services" links={links} />;
}
